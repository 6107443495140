<template>
  <div>
    <v-row>
      <v-col>
        <h3>
          ຂໍ້ມູນລູກຄ້າສັ່ງນ້ຳດື່ມເພີ່ມ
          <span class="primary-color">({{ pagination.total }})</span>
        </h3>
      </v-col>
    </v-row>
    <br>

    <v-card>
      <v-card-text>
        <v-data-table
            :headers="headers"
            :items="customers"
            class="elevation-1"
            :loading="TableLoading"
            :disabled="TableLoading"
            loading-text="Loading... Please wait"
            :disable-pagination="true"
            hide-default-footer
        >
          <!--Action -->
          <template v-slot:item.status="{item}">
            <v-chip label :class="styleValue(item.status)">
              {{getStatus(item.status) }}
            </v-chip>
          </template>
          <template v-slot:item.map="{item}">
            <v-btn @click="openGoogleMap(item.latitude,item.longitude)" class="info">Map</v-btn>
          </template>
          <template v-slot:item.address="{item}">
            <div>
              {{ item.village_name }}, {{ item.district_name }}
            </div>
          </template>
          <template v-slot:item.customer="{item}">
            <div>
              {{ item.name }}
            </div>
          </template>
          <template v-slot:item.created_at="{item}">
            <div>
              {{ moment(item.created_at).format('DD-MM-YYYY hh:mm:ss') }}
            </div>
          </template> <template v-slot:item.updated_at="{item}">
            <div>
              {{ moment(item.updated_at).format('DD-MM-YYYY hh:mm:ss') }}
            </div>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2" @click="EditData(item)">
              mdi-pencil
            </v-icon>
            <v-icon small @click="deleteItem(item.id)">
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
        <br/>
        <Pagination
            v-if="pagination.last_page > 1"
            :pagination="pagination"
            :offset="offset"
            @paginate="fetchData()"
        ></Pagination>
      </v-card-text>
    </v-card>

    <!--Delete Modal-->
    <ModalAdd>
      <template @close="close">
        <v-card>
          <v-card-title>
            <span class="headline">Update</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="form" lazy-validation>
<!--                <v-row>-->
<!--                  <v-col cols="12">-->
<!--                    <v-text-field-->
<!--                        label="ຊື່"-->
<!--                        required-->
<!--                        v-model="edit.name"-->
<!--                    ></v-text-field>-->
<!--                    <p class="errors">-->
<!--                      {{ server_errors.name }}-->
<!--                    </p>-->
<!--                  </v-col>-->
<!--                </v-row>-->

                <v-row>
                  <v-col cols="12">
                    <v-text-field
                        label="ຈຳນວນຕຸກນ້ຳ"
                        required
                        v-model="edit.amount"
                    ></v-text-field>
                    <p class="errors">
                      {{ server_errors.amount }}
                    </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                        label="ລາຍລະອຽດ"
                        required
                        v-model="edit.description"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-select
                        required
                        v-model="edit.status"
                        :items="statuses"
                        item-text="name"
                        item-value="value"
                        label="Status"
                    >
                    </v-select>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="CloseModalAdd()">
                Close
              </v-btn>
              <v-btn
                  color="blue darken-1"
                  text
                  :loading="loading"
                  :disabled="loading"
                  @click="updateItem()"
              >
                Update
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </template>
    </ModalAdd>

    <!--Delete Modal-->
    <ModalDelete>
      <template>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete()"
          >Cancel
          </v-btn
          >
          <v-btn
              color="blue darken-1"
              text
              :loading="loading"
              :disabled="loading"
              @click="deleteItemConfirm()"
          >OK
          </v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </template>
    </ModalDelete>

  </div>
</template>

<script>
import Pagination from "@/plugins/pagination/pagination";

export default {
  components: {
    Pagination,
  },
  data() {
    return {
      loading: false,
      TableLoading: false,
      customers: [],
      server_errors: {
        name: '', surname: '', bottle: '',
        phone: '',
      },
      edit: {},
      //Pagination
      offset: 15,
      pagination: {},
      per_page: 25,
      statuses: [
        {
          name: 'ລໍຖ້າອະນຸມັດ',
          value: 'pending'
        },
        {
          name: 'ກຳລັງຈັດສົ່ງ',
          value: 'on_the_way'
        },
        {
          name: 'ສຳເລັດ',
          value: 'success'
        },
        {
          name: 'ຍົກເລີກ',
          value: 'cancel'
        }
      ],
      headers: [
        {text: "Actions", value: "actions", sortable: false},
        {
          text: "ລຸກຄ້າ", align: "start", sortable: false, value: "customer_name", width: "150px"
        },
        {
          text: "ເບີໂທ", align: "start", sortable: false, value: "phone",
        },
        {
          text: "ຈຳນວນຕຸກນ້ຳ", align: "center",width: "120", sortable: false, value: "amount",
        }, {
          text: "ຕຸກເປົ່າ", align: "center",width: "120", sortable: false, value: "empty_bottle",
        },
        {
          text: "ທີ່ຢູ່", align: "start", sortable: false, value: "address",width: "180"
        },
        {text: "ສະຖານະ", value: "status",width: "180"},
        {
          text: "ລາຍລະອຽດ", align: "start", sortable: false, value: "description",width: "220"
        },
        {
          text: "Created", align: "start", sortable: false, value: "created_at",
        },
        {
          text: "Updated", align: "start", sortable: false, value: "updated_at",
        },
        {text: "Google map", value: "map",width: "180"},
      ],
      toast: {
        value: true, color: 'success', msg: 'Success'
      },
      toast_error: {
        value: true, color: 'error', msg: 'Something when wrong!'
      }
    }
  },
  methods: {
    openGoogleMap(va1,va2) {
      let mapLink = "https://www.google.com/maps?q=" + va1 + "," + va2;
      window.open(mapLink, "_blank");
    },
    getStatus(value){
      if(value == 'pending'){
        return 'ລໍຖ້າອະນຸມັດ';
      } else if(value == 'on_the_way'){
        return 'ກຳລັງຈັດສົ່ງ';
      } else if(value == 'success'){
        return 'ສຳເລັດ';
      } else if(value == 'cancel'){
        return 'ຍົກເລີກ';
      } else {
        return '---';
      }
    },
    styleValue(value){
      if(value == 'pending'){
        return 'primary';
      } else if(value == 'on_the_way'){
        return 'info';
      } else if(value == 'success'){
        return 'success';
      } else if(value == 'cancel'){
        return 'error';
      } else {
        return 'default';
      }
    },
    fetchData() {
      this.TableLoading = true;
      this.$axios.get('list-order-water',
          {
            params: {
              page: this.pagination.current_page,
              per_page: this.per_page,
              status: this.status,
            }
          }).then(res => {
        this.customers = res.data.data.data;
        this.pagination = res.data.data;
        this.TableLoading = false;
      }).catch(() => {
        this.TableLoading = false;
        this.$store.commit("Toast_State", this.toast_error);
      });
    },

    EditData(data) {
      this.edit = data;
      this.$store.commit("modalAdd_State", true);
    },
    updateItem() {
      const data = {
        // name: this.edit.name,
        description: this.edit.description,
        amount: this.edit.amount,
        status: this.edit.status,
      }

      this.$axios.put('/more-order-water/' + this.edit.id, data)
          .then(res => {
            if (res.status == 200) {
              this.loading = false;
              this.edit = {};
              this.CloseModalAdd();
            }
          }).catch(error => {
        this.loading = false;
        this.$store.commit("Toast_State", this.toast_error);
        if (error.response.status == 422) {
          const obj = error.response.data.errors;
          for (let [key, user] of Object.entries(obj)) {
            this.server_errors[key] = user[0];
          }
        }
      });
    },
    CloseModalAdd() {
      this.edit = {};
      this.$store.commit("modalAdd_State", false);
    },

    closeDelete() {
      this.$store.commit("modalDelete_State", false);
    },

    deleteItem(id) {
      this.dataID = id;
      this.$store.commit("modalDelete_State", true);
    }, deleteItemConfirm() {
      this.loading = true;
      this.$axios.delete('more-order-water/' + this.dataID).then(res => {
        if (res.status == 200) {
          setTimeout(() => {
            this.fetchData();
            this.$store.commit("modalDelete_State", false);
            this.loading = false;
            this.$store.commit("Toast_State", this.toast);
          }, 300);
        }
      }).catch(() => {
        this.$store.commit("modalDelete_State", false);
        this.loading = false;
      })
    },
  }, created() {
    this.fetchData();
  },
}
</script>

<style>
.primary-color {
  color: #00c1d2;
}

@import "../../../../public/scss/main.scss";
</style>